
//スクロール表示

document.addEventListener("turbolinks:load", function (e) {
    if ($('#view_consultation').length >= 0) {
        var showFlug = false;
        var topBtn = $('#page-top');
        topBtn.css('bottom', '-75px');
        var showFlug = false;
        $(window).scroll(function () {
            if ($(this).scrollTop() > 50) {
                if (showFlug == false) {
                    showFlug = true;
                    topBtn.stop().animate({'bottom' : '20px'}, 100);
                }
            } else {
                if (showFlug) {
                    showFlug = false;
                    topBtn.stop().animate({'bottom' : '-100px'}, 100);
                }
            }
        });

        //スクロールトップ
        topBtn.click(function () {
            $('body,html').animate({
                scrollTop: 0
            }, 500);
            return false;
        });

        function cat01() {
            $(this).next().slideToggle(300);
        }
        $(".simple .toggle").click(cat01);


        function cat02() {
            $(this).toggleClass("active").next().slideToggle(300);
        }
        $(".switch .toggle").click(cat02);


        // 問診切り替え
        $('#btn_admin_consultation_change').click(function() {
            changeConsultation();
        })
        $(document).on("keypress", "input#admin_consultation_book_code", function(event) {
            if (event.which === 13) changeConsultation();
        })
        function changeConsultation() {
            let book_code = $('#admin_consultation_book_code').val();
            if (book_code.length === 0) {
                alert('バーコード番号を入力してください')
                return false;
            } else if (book_code.length !== 4 && book_code.length < 12) {
                // バーコード読み込みの13桁対応するために、12桁未満のみチェックする（13桁以上はサーバーサイドで切り落とす）
                // ブレスレットが追加されたので4桁は許容する
                alert('バーコード番号は数字4桁または12桁で入力してください')
                return false;
            }
            location.href = `/admin/consultations/${book_code}`
        }

        if ($('#form_step2').length > 0) {
            // 就業中切り替え
            $('input[name="consultation[working]"]').change(function() {
                changeWorkingDisplay($(this).val())
            })
            changeWorkingDisplay($('input[name="consultation[working]"]:checked').val());
            function changeWorkingDisplay(val) {
                if (val === undefined) {
                    $('.step2_content').hide();
                } else {
                    $('.step2_content').show();
                }
                if (val === 'true') {
                    $('.working_hearing').show();
                } else {
                    $('.working_hearing').hide();
                }
            }
        }

    }

    $('.admin_update_booked_on').click(function() {
        const id = $(this).data('id')
        $.ajax({
            type: 'POST',
            url: `/admin/consultations/${id}/admin_update`,
            data: {
                booked_on: $(this).parents('.booked_on').find('.booked_on_input').val(),
                booked_time_hour: $(this).parents('.booked_on').find('.booked_time_hour_input').val(),
                booked_time_min: $(this).parents('.booked_on').find('.booked_time_min_input').val()
            },
            dataType: 'script'
        })
    })

    $('.admin_update_booked_number').click(function() {
        const id = $(this).data('id')
        $.ajax({
            type: 'POST',
            url: `/admin/consultations/${id}/admin_update`,
            data: { booked_number: $(this).parents('.booked_number').find('.booked_number_input').val() },
            dataType: 'script'
        })
    })

    $('#consultations_all_check').change(function() {
        const checks = $('.print_id_check');
        checks.prop('checked', $(this).prop('checked'))
    })
});
