require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()

window.jQuery = jQuery;

require("../stylesheets/consultation.scss")
require("../src/consultation")
require("../src/front")

global.jQuery, global.$ = require("jquery");