document.addEventListener("turbolinks:load", function (e) {
    if ($('#booked_number').val()?.length > 0) {
        window.onbeforeunload = function(e) {
            e.returnValue = "入力された内容がクリアされます。よろしいですか？";
        }
        $('form').submit(function() {
            window.onbeforeunload = null;
        });
    } else {
        window.onbeforeunload = null;
    }
})